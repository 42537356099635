import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "Which industries does your UI UX Design Company in USA serve?",
  "How do you approach a UI UX design project?",
  "What makes Octet stand out as the best Website Design Agency in USA?",
  "What is the standard timeline for a UI UX design project?",
  "Can your Web Design Agency in USA create web and mobile apps?",
];

const MumbaiPage = () => {
  const banner = {
    title: `UI UX Design Agency in USA`,
    content: "Our adaptive design allows you to bring concepts to market quickly. With our <strong class='font-semibold'><a title='Octet design studio' class='link-text-para' href='/'>UI UX Design company</a></strong> in USA, you can achieve seamless, engaging, and intuitive success.",
    img: ["why-octet-usa.webp"],
    imageTitle: "connect with Octet",
    imageAlt: "connect with the top UI UX design company in USA",
  };
  const projectData = [
    {
      title: "Unilogix App",
      desc: "A Logistics Insights Application",
      Industry: "Logistics",
      firstAlt: "Mobile App UI UX Design to track logistics performance",
      firstTitle: "Mobile UI Design to Track Logistics",
      secondAlt: "Mobile App UI UX Design to check order details",
      secondTitle: "Mobile UI Design to View Order Details",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp"],
    },
    {
      title: "Ship Delight",
      desc: "An E-Commerce Logistic Website",
      Industry: "Logistics",
      firstAlt: "UI UX Design to check the real time shipment status",
      firstTitle: "UI Design to Track Shipment Status",
      secondAlt: "Dashboard UI UX Design to check NDR Status",
      secondTitle: "NDR Status's UI Screen",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Our best UI UX design company in USA has experience in various industries, including  healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions.`,
      ],
    },
    {
      para: [
        `Octet, the best UI UX design agency in USA , collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. Our website design agency  in USA approach involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing.`,
      ],
    },
    {
      para: [
        `Our team of exceptional designers, proactive approach, and dedication to innovation make our agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner.`,
      ],
    },
    {
      para: [
        `The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months.`,
      ],
    },
    {
      para: [
        `Yes, we are known for being the top web design agency in USA. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Build your Project with our Human Intelligence",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Full-Service UI UX Design Agency in USA",
    des: "We are a full-service web design agency, USA. We provide a unique blend of creative designs and development solutions for SaaS technologies to help your business thrive.",
    items: [
      {
        title: "User Research",
        content:
          "Our research-driven approach enables us to create tailored interfaces that align with your business objectives. As the <strong class='font-semibold'>best UI UX design company in USA</strong>, we enhance online visibility and user experience.",
        link: "/user-research/",
        linkText: 'Our <a href="/user-research/" title="User Research Agency">user research agency</a> develops strategies to enhance digital visibility and user experience.'
      },
      {
        title: "UI Development",
        content:
          "We craft dynamic, engaging platforms that captivate users and increase their sessions. As USA’s best UI UX design company, we craft custom user interfaces using design principles and core industry expertise.",
        link: "/ui-development/",
        linkText: 'Our <a href="/ui-development/" title="UI development company">UI development company</a> creates engaging experiences by putting user-centric design upfront.'
      },
      {
        title: "UI UX Designing",
        content:
          "Octet is the best UI UX design agency in USA. We offer efficient, streamlined solutions that bring your digital vision to life. We fuse originality and functionality to design creative, attractive interfaces that provide seamless, engaging user experiences.",
        link: "/ui-ux-designing/",
        linkText: 'Our <a href="/ui-ux-designing/" title="UI UX design agency">UI UX design agency</a> has a plethora of experience providing customized solutions for various industries.'
      },
      {
        title: "Usability Testing",
        content:
          "We use advanced usability metrics to accurately identify user pain points and fine-tune the interface elements for more promising engagement. As the <strong class='font-semibold'>best website design agency in USA</strong>, we employ advanced techniques to optimize your user experiences.",
        link: "/usability-testing/",
        linkText: 'Our <a href="/usability-testing/" title="usability testing company">usability testing company</a> uses improved procedures to ensure your user experiences are well-optimized.'
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const faqDes = `We've compiled frequently asked questions to provide quick and informative answers about our UI UX company and web design agency in USA, the creation process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "<strong class='font-semibold'><a href='/' title='Octet design studio' class='link-text-para'>Octet</a></strong> is the <strong class='font-semibold'>top website design agency in USA</strong>  with over years of experience transforming SaaS, B2B, and enterprise-grade products. Our proactive approach simplifies complex applications, delivering intuitive, user-centric solutions across diverse industries. Being the top UI UX design agency in USA, we operate with an agile process that empowers businesses to unlock their potential and create functional, visually appealing products.",
  ];
  const knowAboutTitle = 'Partner with the Top Web Design Agency in USA';
  const commonContent = {
    title: "Amplify Experience with Our Top Website Design Agency in USA",
    para: ["At our top website design agency in USA, we are dedicated to creating visually appealing and user-centric solutions that enhance your brand's digital visibility."],
    content: [
      {
        title: "Cross-Platform Adaptability",
        text: "We prioritize responsive designs that work seamlessly across all devices and screen sizes, enhancing user accessibility. We are your go-to web design agency in USA, aiming to deliver exceptional UI UX solutions tailored to your needs.",
      },
      {
        title: "User-Centric Approach",
        text: "We adopt a user-centric approach to product development and prioritize their needs and preferences. Our website design agency in USA involves customizing the product to ensure customer satisfaction.",
      },
      {
        title: "Continuous Improvement",
        text: "Our design methodology is based on a continuous improvement process informed by extensive user feedback & market data. Our UI UX design agency in USA ensures that our designs align with user behavior & remain responsive.",
      },
      {
        title: "Increased Conversion Rates",
        text: "Our website design agency in USA approaches to design optimization are grounded in data-driven insights and proven strategies. This results in a website that effectively engages and converts its audience.",
      }
    ],
  };
  const commonContent1 = {
    title: "Design Process by our UI UX Design Agency in USA",
    para: ['Our research-driven UI UX design agency in USA has transformed businesses by creating customer-centric solutions that allow them to serve millions of people.'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our <strong class='font-semibold'>UI UX design company in USA</strong> carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to grasp their issues fully. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our web design agency in USA strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "As the USA’s best UI UX design agency, we formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with the best UI UX solutions that enhance the user experience and maximize your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "After finalizing the layout and designs, we develop a prototype and test the end product's functionality. This enables our UI UX design agency in USA to evaluate the final product, ensuring it meets the required standards and specifications.",
      }
    ],
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Which industries does your UI UX Company in USA serve?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our best UI UX design company in USA has experience in various industries, including healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions."
                }
              },{
                "@type": "Question",
                "name": "How do you approach a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Octet, the best UI UX design agency in USA , collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. Our top UI design company in USA approach involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing."
                }
              },{
                "@type": "Question",
                "name": "What makes Octet stand out as the best UI Design Company in USA?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our team of exceptional designers, proactive approach, and dedication to innovation make our agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner."
                }
              },{
                "@type": "Question",
                "name": "What is the standard timeline for a UI UX design project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months."
                }
              },{
                "@type": "Question",
                "name": "Can your Web Design Agency in USA create web and mobile apps?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we are known for being the best web design agency in USA . Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes."
                }
              }]
            }
          `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          page={"ahmedabad-home"}
          noPhone={true}
          location={`131 Continental Dr, Suite 305 <br /> Newark, Delaware, 19713`}
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout des={knowAboutDes} title={knowAboutTitle} imageAlt={"Octet's Logo- UI UX Design Company in USA"} imageTitle={"UI UX Design Agency's Logo"} />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent data={commonContent1} mode={"light"} list='single' nopara />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                Successful UX Projects by Octet
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default MumbaiPage;

export const Head = () => (
  <Seo
    title="UI UX Design Agency in USA | Top Web Design Company in USA"
    description="Octet is your reliable UI UX design agency in the USA. Elevate your online presence with our top web design company."
  />
);
